import * as React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface Props {
  links?: any;
}
interface State {}

class SocialMedia extends React.Component<Props, State> {
  public render() {
    return (
      <div className="flex justify-left space-x-4">
        {this.props.links.twitter && (
          <a
            href={this.props.links.twitter}
            className="inline-block text-lg text-white hover:text-teal-500"
          >
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faTwitter} />
          </a>
        )}
        {this.props.links.facebook && (
          <a
            href={this.props.links.facebook}
            className="inline-block text-lg text-white hover:text-teal-500"
          >
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faFacebook} />
          </a>
        )}
        {this.props.links.linkedin && (
          <a
            href={this.props.links.linkedin}
            className="inline-block text-lg text-white hover:text-teal-500"
          >
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faLinkedin} />
          </a>
        )}
        {this.props.links.youtube && (
          <a
            href={this.props.links.youtube}
            className="inline-block text-lg text-white hover:text-teal-500"
          >
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faYoutube} />
          </a>
        )}
        {this.props.links.instagram && (
          <a
            href={this.props.links.instagram}
            className="inline-block text-lg text-white hover:text-teal-500"
          >
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faInstagram} />
          </a>
        )}
      </div>
    );
  }
}

export default SocialMedia;
