import * as React from "react";
import { Link } from "gatsby";

import Nav, { ILink } from "./ui/nav";
import SocialMedia from "./ui/socialmedia";

interface Props {
  website_name: string;
  logo: string;
  logo_alt: string;
  main_menu: ILink[];
  social_menu: any;
}

interface State {
  openNav?: boolean;
}

class Navbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openNav: false,
    };
  }
  public render() {
    return (
      <div className="sticky top-0 z-10">
        <nav className="flex items-center justify-between flex-wrap shadow-md bg-blue-900 p-6 relative z-10 border-b-4 border-teal-500">
          <Link
            to="/"
            className="flex items-center flex-shrink-0 text-white mr-6"
          >
            <img
              className="fill-current mr-2"
              style={{ width: "48px" }}
              src={this.props.logo}
              alt={this.props.logo_alt}
            />
            <span className="font-bold text-md lg:text-xl">
              {this.props.website_name}
            </span>
          </Link>
          <div className="block lg:hidden">
            <button
              onClick={() => this.setState({ openNav: !this.state.openNav })}
              className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden">
            <Nav links={this.props.main_menu} />
            <SocialMedia links={this.props.social_menu} />
          </div>
        </nav>
        <div
          className={"bg-gray-900 px-6 py-3 w-full flex-grow absolute "}
          style={
            this.state.openNav
              ? { top: 105, transition: "850ms" }
              : { top: "-200%", transition: "350ms" }
          }
        >
          <Nav links={this.props.main_menu} />
          <div className="mt-4 lg:mt-0 ">
            <SocialMedia links={this.props.social_menu} />
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
