import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import SocialMedia from "../components/ui/socialmedia";

interface Props {
  website_name: string;
  logo: string;
  logo_alt: string;
  background: string;
  background_alt: string;
  social_menu: any;
}

interface State {}

const Gradient = styled.div`
  background: rgb(42, 67, 101);
  background: linear-gradient(
    135deg,
    rgba(42, 67, 101, 1) 0%,
    rgba(44, 82, 130, 1) 61%,
    rgba(43, 108, 176, 0.4051821412158614) 100%
  );
`;

class Footer extends React.Component<Props, State> {
  public render() {
    return (
      <div>
        <div
          className="bg-blue-900 text-white bg-cover bg-center"
          style={{ backgroundImage: `url(${this.props.background})` }}
        >
          <Gradient className="px-10 py-10 flex space-x-3">
            <div className="w-full lg:w-1/3">
              <div className="flex pb-2 border-b-2 border-white">
                <div className="w-2/12 md:w-1/12 mr-2">
                  <img
                    className="fill-current mr-2"
                    src={this.props.logo}
                    alt={this.props.logo_alt}
                  />
                </div>
                <div className="w-10/12 md:11/12 ">
                  <h3 className="font-bold">{this.props.website_name}</h3>
                  <p className="text-gray-300">
                    <FontAwesomeIcon
                      style={{ maxWidth: "17px" }}
                      icon={faCopyright}
                    />{" "}
                    All Rights Reserved - 2021
                  </p>
                </div>
              </div>
              <Link className="text-white hover:text-teal-500" to="/contact-us">
                Contact us
              </Link>
            </div>
          </Gradient>
        </div>
        <div className="bg-gray-800 px-10 py-1">
          <div className="w-full flex lg:items-center lg:w-auto">
            <SocialMedia links={this.props.social_menu} />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
