import * as React from "react";

interface Props {
  background?: string;
}

interface State {}

class Section extends React.Component<Props, State> {
  public render() {
    return (
      <div className={"pt-20 pb-20 " + this.props.background}>
        {this.props.children}
      </div>
    );
  }
}

export default Section;
