import * as React from "react";
import { Link } from "gatsby";

export interface ILink {
  title: string;
  path: string;
}

interface Props {
  links: ILink[];
}

interface State {}

class Nav extends React.Component<Props, State> {
  public render() {
    return (
      <div className="text-sm lg:flex-grow">
        <Link
          to="/"
          className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-teal-400 mr-4"
        >
          Home
        </Link>
        {this.props.links.map((link, i) => (
          <Link
            to={link.path}
            key={i}
            className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-teal-400 mr-4"
          >
            {link.title}
          </Link>
        ))}
      </div>
    );
  }
}

export default Nav;
