import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import Theme from "../components/theme";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const menu = [
  {
    title: "Products",
    path: "/products",
  },
  {
    title: "Downloadables",
    path: "/resources",
  },
  {
    title: "Educational Services",
    path: "/services",
  },
  {
    title: "About us",
    path: "/about-us",
  },
  {
    title: "Contact us",
    path: "/contact-us",
  },
];

function BasicLayout(props) {
  return (
    <StaticQuery
      query={graphql`
        query BasicLayoutQuery {
          site: dataYaml(type: { eq: "site_info" }) {
            title
            footer_image
            logo
            social_media {
              facebook
              linkedin
            }
            metadata
            description
            favicon
          }
        }
      `}
      render={(data) => (
        <div>
          <Helmet
            title={`${props.pageTitle} | ${data.site.title}`}
            titleTemplate="%s · Better Learning for Better Life"
          >
            <meta name="description" content={data.site.description} />
            <meta name="keywords" content={data.site.description} />
            <meta name="image" content={data.site.logo} />
            <link
              rel="icon"
              type="image/png"
              href={data.site.favicon}
              sizes="16x16"
            />
            {/* {seo.url && <meta property="og:url" content={seo.url} />}
          {(props.product ? true : null) && (
            <meta property="og:type" content="product" />
          )}
          {seo.title && <meta property="og:title" content={seo.title} />}
          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {seo.image && <meta property="og:image" content={seo.image} />} */}
          </Helmet>
          <Theme>
            <div className="bg-gray-200">
              <Navbar
                website_name={data.site.title}
                logo={data.site.logo}
                logo_alt={""}
                main_menu={menu}
                social_menu={data.site.social_media}
              />
              {props.children}
              <Footer
                website_name={data.site.title}
                logo={data.site.logo}
                logo_alt={""}
                background={data.site.footer_image}
                background_alt={""}
                social_menu={data.site.social_media}
              />
            </div>
          </Theme>
        </div>
      )}
    />
  );
}

export default BasicLayout;
