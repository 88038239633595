import * as React from "react";
import styled from "styled-components";

interface Props {
  padding?: boolean;
}

interface State {}

const Wrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: 640px) {
    max-width: 640px;
  }
  @media only screen and (min-width: 768px) {
    max-width: 768px;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1024px;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
  }
  @media only screen and (min-width: 1550px) {
    max-width: 1550px;
  }
  @media only screen and (min-width: 1700px) {
    max-width: 1700px;
  }
`;

class Container extends React.Component<Props, State> {
  public render() {
    return (
      <Wrapper className={"mx-auto" + (this.props.padding ? " px-5" : "")}>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default Container;
